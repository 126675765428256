import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import loader2 from "../assets/images/loader/loader2.gif";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import Form from "react-bootstrap/Form";

function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    rtl: true,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [form, setform] = useState({
    Name: "",
    CourseName: "",
    Country: "",
    EmailId: "",
    PhoneNumber: "",
    Comments: "",
    City: "",
  });
  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    addUsers();
  };
  const addUsers = () => {
    var params = {
      Name: form.Name,
      CourseName: form.CourseName,
      Country: form.Country,
      PhoneNumber: form.PhoneNumber,
      EmailId: form.EmailId,
      Comments: form.Comments,
      City: form.City,
    };
    axios
      .post(
        "https://api.erptree.com/v1/ertptreeapi/admin/emailsend",
        params
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(
              "Added Successfully. Your data received, Our team will get back to you soon!"
            );
            clearInput();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const clearInput = () => {
    setform({
      Name: "",
      CourseName: "",
      Country: "",
      EmailId: "",
      PhoneNumber: "",
      Comments: "",
      City: "",
    });
  };
  return (
    <>
      {isLoading == true ? (
        <>
          <div className="text-center">
            <img
              src={loader2}
              style={{
                marginTop: "250px",
                width: "100px",
              }}
            ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="banner-area bg-color-f7f8f9">
            <div className="container-fluid" style={{ paddingBottom: "200px" }}>
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="banner-content">
                    <span className="top-title">Best Learning Platform</span>
                    <h1>
                      The Best Place For The Oracle
                      <span>Fusion Trainings.</span>
                    </h1>
                    <p>
                      Compare with other online Trainings Institutes, erptree is
                      best of them which offer online Trainings and classroom
                      training with best-experienced trainers.
                    </p>
                    <div className="banner-btn">
                      <a href="#Courses" className="default-btn">
                        Find Courses
                        <i className="ri-search-line" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="banner-img">
                    <img
                      src="assets/images/sizedimages/about.png"
                      alt="Image"
                    />

                    <img
                      src="assets/images/banner/shape-8.png"
                      className="shape shape-8"
                      alt="Image"
                    />
                    <img
                      src="assets/images/banner/shape-9.png"
                      className="shape shape-9"
                      alt="Image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="shape shape-2" data-speed="0.05" data-revert="true">
              <img src="assets/images/banner/shape-2.png" alt="Image" />
            </div>
            <div className="shape shape-3" data-speed="0.06" data-revert="true">
              <img src="assets/images/banner/shape-3.png" alt="Image" />
            </div>
            <div className="shape shape-4" data-speed="0.07" data-revert="true">
              <img src="assets/images/banner/shape-4.png" alt="Image" />
            </div>
            <div className="shape shape-5" data-speed="0.08" data-revert="true">
              <img src="assets/images/banner/shape-5.png" alt="Image" />
            </div>
            <div className="shape shape-6" data-speed="0.09" data-revert="true">
              <img src="assets/images/banner/shape-6.png" alt="Image" />
            </div>
            <div className="shape shape-7" data-speed="0.09" data-revert="true">
              <img src="assets/images/banner/shape-7.png" alt="Image" />
            </div>
          </div>

          <div className="features-area" style={{ paddingTop: "60px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="single-features d-flex justify-content-start align-items-center">
                    <img
                      src="assets/images/icons/icon.png"
                      alt="Image"
                      width="80px"
                      height="80px"
                    />
                    <h3>Online Training</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-features d-flex justify-content-start align-items-center">
                    <img
                      src="assets/images/icons/icon1.png"
                      alt="Image"
                      width="80px"
                      height="80px"
                    />
                    <h3>Full Day Training</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-features d-flex justify-content-start align-items-center">
                    <img
                      src="assets/images/icons/icon2.png"
                      alt="Image"
                      width="80px"
                      height="80px"
                    />
                    <h3>Self Placed Training</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-features d-flex justify-content-start align-items-center">
                    <img
                      src="assets/images/icons/icon4.png"
                      alt="Image"
                      width="80px"
                      height="80px"
                    />
                    <h3>Recorded Videos</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="about-us" style={{ paddingTop: "70px" }}>
            <div
              className="about-area"
              style={{ paddingTop: "60px", paddingBottom: "60px" }}
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="about-img">
                      <img
                        src="assets/images/sizedimages/sucessbg.png"
                        alt="Image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="about-content">
                      <h1 style={{ color: "#b12b35", fontWeight: "bold" }}>
                        ABOUT US
                      </h1>
                      <h3>
                        Smart Tutoring Program for Success <span>People</span>
                      </h3>
                      <p>
                        Welcome to our esteemed online Oracle Institute, where
                        innovation meets education! At our institute, we are
                        dedicated to empowering individuals with the knowledge
                        and skills essential for navigating the dynamic realm of
                        Oracle technologies.
                        <p>
                          What sets us apart is our commitment to providing a
                          virtual learning environment that transcends
                          traditional boundaries. Our expert instructors bring a
                          wealth of industry experience to the virtual
                          classroom, ensuring that you receive practical
                          insights that go beyond theoretical knowledge.
                        </p>
                        <p>
                          As a participant in our online Oracle Institute, you
                          can expect a curriculum that reflects the latest
                          industry trends and advancements. We believe in the
                          importance of hands-on learning, and our courses are
                          designed to equip you with the practical skills
                          necessary to excel in real-world Oracle environments.
                        </p>
                        <p>
                          Join our vibrant community of learners who share a
                          common goal of mastering Oracle technologies. Whether
                          you aspire to become a certified Oracle professional
                          or are eager to stay ahead in your career, our
                          institute is the gateway to unlocking your full
                          potential.
                        </p>
                        <p>
                          Your success in the world of Oracle awaits! Our Oracle
                          IT Institute offers a diverse range of courses
                          designed to cater to various skill levels and career
                          aspirations. Whether you're a beginner aiming to
                          establish a strong foundation or an experienced
                          professional seeking advanced expertise, our
                          curriculum is tailored to meet your specific needs.
                          Here are some of the key courses available at our
                          Oracle IT Institute:
                        </p>
                      </p>

                      <a
                        onClick={handleShow}
                        className="default-btn"
                        type="button"
                      >
                        Join For Feature
                        <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ paddingTop: "1px" }} id="Courses">
            <div className="motivation-area ">
              <div className="container pb-70">
                <div className="section-title left-title">
                  <h1 style={{ color: "#b12b35", fontWeight: "bold" }}>
                    ONLINE COURSES
                  </h1>
                  <h3>
                    Explore Featured <span>Courses</span>
                  </h3>
                </div>
                <div className="row">
                  <p className="mb-5">
                    Enroll in our courses to gain in-depth knowledge, hands-on
                    experience, and industry-recognized certifications that will
                    propel your career in the dynamic field of Oracle IT.
                    Whether you're looking to enhance your skills in database
                    management, application development, or cloud solutions, our
                    Oracle IT Institute has the right program for you.
                  </p>
                </div>
                <Slider {...settings}>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle1.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">Oracle Fusion Cloud Financials</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle2.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">Oracle Fusion Cloud SCM</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle3.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">Oracle Fusion Cloud Procurement</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle4.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">Oracle Fusion Cloud PPM</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle5.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">
                            Oracle Fusion Cloud HCM Functional
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle6.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">
                            Oracle Fusion Cloud HCM Technical
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle7.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">
                            Oracle Fusion Accounting Hub (FAH)
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle8.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">Oracle R12 Financials</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle9.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">
                            Oracle R12 Project Accounting (PA)
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle10.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">Oracle R12 HRMS</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle11.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">Oracle R12 Apps Technical</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="single-courses-item">
                      <div className="courses-img">
                        <a href="#Courses">
                          <img
                            src="assets/images/sizedimages/oracle12.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="courses-content">
                        <h3>
                          <a href="#Courses">Oracle SQL & PL SQL</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>

            <div
              className="motivation-area"
              style={{ paddingTop: "80px" }}
              id="JobCourse"
            >
              <div
                class="our-instructors-area pb-70 bg-color-f7f8f9"
                style={{ paddingTop: "60px" }}
              >
                <div class="container">
                  <div class="section-title">
                    <h1 style={{ color: "#b12b35", fontWeight: "bold" }}>
                      {" "}
                      OFFLINE
                    </h1>
                    <h4>
                      Full-Day Realtime <span>Job Guarentee </span>Program.
                    </h4>
                  </div>

                  <div class="row justify-content-center">
                    <div class="col-md-6 col-sm-6">
                      <div class="single-instructors-item">
                        <img
                          src="assets/images/sizedimages/jobguarentee1.png"
                          alt="Image"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="instructors-content ">
                        <h6 id="content">
                          <a href="#JobCourse">
                            <b style={{ color: "#2a2828" }}>1.</b> 6 Months Full
                            Day Duration Training.
                          </a>
                        </h6>
                        <br></br>
                        <h6>
                          <a href="#JobCourse">
                            <b style={{ color: "#2a2828" }}>2.</b> Realtime
                            Exposure.
                          </a>
                        </h6>
                        <br></br>
                        <h6>
                          <a href="#JobCourse">
                            <b style={{ color: "#2a2828" }}>3.</b> Get in-depth
                            knowledge about Business Process.
                          </a>
                        </h6>
                        <br></br>
                        <h6>
                          <a href="#JobCourse">
                            <b style={{ color: "#2a2828" }}>4.</b> Many
                            activities involved.
                          </a>
                        </h6>
                        <br></br>
                        <h6>
                          <a href="#JobCourse">
                            <b style={{ color: "#2a2828" }}>5. </b>Special
                            Attention for Everyone as part of this program
                            etc... few things will be added or more if needed.
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="motivation-area ptb-100">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="motivation-img">
                      <img
                        src="assets/images/sizedimages/motivation1.png"
                        alt="Image"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="motivation-content">
                      <h2>
                        Transform Your Life Best Through <span>Skillset</span>
                      </h2>
                      <p style={{paddingTop:"40px" ,paddingBottom:"20px"}}>
                        Our people make us who we are, and were committed to
                        their success. With a supportive workplace culture and
                        countless opportunities for growth, each member of the
                        erptree community is empowered to be their best self,
                        both professionally and personally .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="contact-us" style={{ paddingTop: "130px" }}>
            <div className="contact-area pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6" style={{ background: "white" }}>
                    <div className="section-title left-title">
                      <h1 style={{ color: "#b12b35", fontWeight: "bold" }}>
                        CONTACT US
                      </h1>
                      <h4>Get In Touch With Us</h4>
                    </div>

                    <div className="contact-form">
                      <form
                        id="contactForm"
                        onSubmit={(e) => {
                          HandleSubmit(e);
                        }}
                      >
                        <div className="row">
                          <div className="col-md-6 col-sm-12 mb-3">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Label>
                                Your Name <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="Name"
                                value={form.Name}
                                required
                                placeholder="Enter First Name.."
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-6 col-sm-12 mb-3">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Label>
                                Email address
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="email"
                                name="EmailId"
                                value={form.EmailId}
                                placeholder="Enter email.."
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-6 col-sm-12 mb-3">
                            <Form.Group controlId="formFile">
                              <Form.Label>
                                Course Name
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="CourseName"
                                value={form.CourseName}
                                required
                                placeholder="Enter Course Name.."
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-6 col-sm-12 mb-3">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Label>
                                Phone Number
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="PhoneNumber"
                                value={form.PhoneNumber}
                                required
                                placeholder="Enter Phone Number.."
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-6 col-sm-12 mb-3">
                            <label className="pb-2">Country</label>
                            <span className="text-danger">*</span>
                            <select
                              name="Country"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              controlId="formFile"
                              value={form.Country}
                              required
                              className="form-select"
                              style={{ background: "#f4f4f4" }}
                            >
                              <option value="">Select Country</option>
                              <option value="Australia">Australia</option>
                              <option value="Canada">Canada</option>
                              <option value="China">China</option>
                              <option value="Japan"> Japan</option>
                              <option value="India">India</option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="Singapore">Singapore</option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Others">Others</option>
                            </select>
                            {form.Country == "Others" ? (
                              <>
                                <Form.Group
                                  controlId="exampleForm.ControlInput1"
                                  className="mt-2"
                                >
                                  <Form.Label>
                                    Others Country / City
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="City"
                                    value={form.City}
                                    required
                                    placeholder="Enter Country / City.."
                                    onChange={(e) => {
                                      handlechange(e);
                                    }}
                                  />
                                </Form.Group>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-md-6 col-sm-12 mb-3">
                            <Form.Group
                              className="mb-2"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>
                                Comments <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder="Enter Comments.."
                                name="Comments"
                                value={form.Comments}
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-12 col-sm-12 mb-3">
                            <Button
                              variant="primary"
                              type="submit"
                              style={{ float: "right" }}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="map-area">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.323179753559!2d78.3935541!3d17.4920795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb918eb6921b7d%3A0x81590ec7359ee666!2sManjeera%20Majestic%20Commercial!5e0!3m2!1sen!2sin!4v1711727100977!5m2!1sen!2sin"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <ToastContainer />

          <Modal show={show} centered>
            <Modal.Header>
              <Modal.Title style={{ color: "#b12b35" }}>
                GET IN TOUCH WITH US!
              </Modal.Title>
            </Modal.Header>
            <Form
              onSubmit={(e) => {
                HandleSubmit(e);
              }}
            >
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-3">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>
                        Your Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="Name"
                        value={form.Name}
                        required
                        placeholder="Enter First Name.."
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>
                        Email address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="EmailId"
                        value={form.EmailId}
                        placeholder="Enter email.."
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <Form.Group controlId="formFile">
                      <Form.Label>
                        Course Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="CourseName"
                        value={form.CourseName}
                        required
                        placeholder="Enter Course Name.."
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>
                        Phone Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="PhoneNumber"
                        value={form.PhoneNumber}
                        required
                        placeholder="Enter Phone Number.."
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-sm-12 mb-3">
                    <label className="pb-2">Country</label>{" "}
                    <span className="text-danger">*</span>
                    <select
                      name="Country"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      controlId="formFile"
                      value={form.Country}
                      required
                      className="form-select"
                      style={{ background: "#f4f4f4" }}
                    >
                      <option value="">Select Country</option>
                      <option value="Australia">Australia</option>
                      <option value="Canada">Canada</option>
                      <option value="China">China</option>
                      <option value="Japan"> Japan</option>
                      <option value="India">India</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United Arab Emirates">
                        United Arab Emirates
                      </option>
                      <option value="Singapore">Singapore</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Others">Others</option>
                    </select>
                    {form.Country == "Others" ? (
                      <>
                        <Form.Group
                          controlId="exampleForm.ControlInput1"
                          className="mt-2"
                        >
                          <Form.Label>
                            Others Country / City{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="City"
                            value={form.City}
                            required
                            placeholder="Enter Country / City.."
                            onChange={(e) => {
                              handlechange(e);
                            }}
                          />
                        </Form.Group>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        Comments <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter Comments.."
                        name="Comments"
                        value={form.Comments}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
            <p className="dot" onClick={handleClose}>
              .
            </p>
          </Modal>
        </>
      )}
    </>
  );
}

export default Home;
