import React from "react";

function Footer() {
  return (
    <>
      <div className="footer-area">
        <div className="pt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-sm-6">
                <div className="single-footer-widget">
                  <a href="#">
                    <img
                      src="assets/images/pic.png"
                      style={{ background: "white" }}
                      alt="Image"
                      width="80px"
                    />
                  </a>
                  <p className="mt-2">
                    Compare with other online training Institutes, erptree is
                    best of them which offer online training and classroom
                    training with best-experienced trainers .
                  </p>
                  <ul className="social-link mt-2">
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="ri-facebook-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="ri-twitter-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="ri-instagram-line" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="single-footer-widget">
                  <h3>Useful Link</h3>
                  <ul className="use-link">
                    <li className="mt-3">
                      <a href="#" className="mt-3">
                        <i className="ri-arrow-right-line" />
                        Home
                      </a>
                    </li>
                    <li className="mt-3">
                      <a href="#about-us">
                        <i className="ri-arrow-right-line" />
                        About Us
                      </a>
                    </li>

                    <li className="mt-3">
                      <a href="#Courses">
                        <i className="ri-arrow-right-line" />
                        Courses
                      </a>
                    </li>
                    <li className="mt-3">
                      <a href="#JobCourse">
                        <i className="ri-arrow-right-line" />
                        Job Courses
                      </a>
                    </li>

                    <li className="mt-3">
                      <a href="#contact-us">
                        <i className="ri-arrow-right-line" />
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single-footer-widget">
                  <h3>Information</h3>
                  <ul className="information-link">
                    <li>
                      <i className="ri-headphone-line" />
                      <span>Call Us</span>
                      <a href="tel:+919666183940">+91 96661 83940</a>
                      <a href="tel:+918886096632">+91 88860 96632</a>
                    </li>
                    <li>
                      <i className="ri-mail-line" />
                      <span>Email Us </span>
                      <a href="mailto:erptree.com@gmail.com">
                        erptree.com@gmail.com
                      </a>
                    </li>
                    <li>
                      <i className="ri-map-pin-line" />
                      <span>Location</span>
                      #718, Manjeera Majestic Commercial. NTU Rd, Beside
                      Manjeera Mall, Kukatpally Housing Board Colony,
                      Kukatpally, Hyderabad, Telangana - 500072.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right-area">
          <div className="container">
            <div className="copy-right-content">
              <p>
                Copyright © 2024 ERPtree All Rights Reserved - Designed by
                <a href="https://digitalraiz.com/" target="_blank"> DigitalRaiz</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
