import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Header() {
  const [path, setpath] = useState([]);

  const local = (data) => {
    setpath(data);
  };
  useEffect(() => {
    handleShow();
  }, []);

  const [form, setform] = useState({
    Name: "",
    CourseName: "",
    Country: "",
    EmailId: "",
    PhoneNumber: "",
    Comments: "",
    City: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setopen] = useState(false);

  const show1 = () => {
    setopen(!open);
  };

  const show2 = () => {
    setopen(false);
  };

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    addUsers();
  };

  const addUsers = () => {
    var params = {
      Name: form.Name,
      CourseName: form.CourseName,
      Country: form.Country,
      PhoneNumber: form.PhoneNumber,
      EmailId: form.EmailId,
      Comments: form.Comments,
      City: form.City,
    };
    axios
      .post(
        "https://api.erptree.com/v1/ertptreeapi/admin/emailsend",
        params
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(
              "Added Successfully. Your data received, Our team will get back to you soon!"
            );
            handleClose();
            clearInput();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const clearInput = () => {
    setform({
      Name: "",
      CourseName: "",
      Country: "",
      EmailId: "",
      PhoneNumber: "",
      Comments: "",
      City: "",
    });
  };

  return (
    <>
      <div className=" navbar-area is-sticky">
        <div className="mobile-responsive-nav">
          <div className="container">
            <div className="mobile-responsive-menu mean-container">
              <div className="mean-bar">
                <a
                  href="#nav"
                  onClick={() => {
                    show1();
                  }}
                  role="button"
                  tabIndex={0}
                  className={
                    open == true
                      ? "meanmenu-reveal meanclose"
                      : "meanmenu-reveal "
                  }
                >
                  {open == true ? (
                    "X"
                  ) : (
                    <span>
                      <span>
                        <span />
                      </span>
                    </span>
                  )}
                </a>
                <nav className="mean-nav">
                  <ul
                    className={
                      open
                        ? "navbar-nav m-auto"
                        : "navbar-nav m-auto headerclose hidden"
                    }
                  >
                    <li
                      className="nav-item "
                      onClick={() => {
                        local("#");
                      }}
                    >
                      <a
                        href="#"
                        className={path == "#" ? "nav-link active" : ""}
                        onClick={() => {
                          show2();
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li
                      className="nav-item "
                      onClick={() => {
                        local("about-us");
                      }}
                    >
                      <a
                        href="#about-us"
                        className={path == "about-us" ? "nav-link active" : ""}
                        onClick={() => {
                          show2();
                        }}
                      >
                        About Us
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        local("Courses");
                      }}
                    >
                      <a
                        href="#Courses"
                        className={path == "Courses" ? "nav-link active" : ""}
                        onClick={() => {
                          show2();
                        }}
                      >
                        Courses
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        local("JobCourse");
                      }}
                    >
                      <a
                        href="#JobCourse"
                        className={path == "JobCourse" ? "nav-link active" : ""}
                        onClick={() => {
                          show2();
                        }}
                      >
                        Job Courses
                      </a>
                    </li>

                    <li
                      className="nav-item"
                      onClick={() => {
                        local("contact-us");
                      }}
                    >
                      <a
                        href="#contact-us"
                        className={
                          path == "contact-us" ? "nav-link active" : ""
                        }
                        onClick={() => {
                          show2();
                        }}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                  <div className="others-options">
                    <ul className="d-flex justify-content-between align-items-center">
                      <li>
                        <a
                          className="default-btn"
                          onClick={handleShow}
                          type="button"
                        >
                          Get Started
                          <i className="ri-edit-line" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="logo">
                <a href="#">
                  <img
                    src="assets/images/pic.png"
                    className="main-logo"
                    alt="logo"
                    style={{ height: "70px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="desktop-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="#">
                <img
                  src="assets/images/pic.png"
                  className="main-logo"
                  alt="logo"
                  style={{ height: "80px" }}
                />
                <img
                  src="assets/images/pic.png"
                  className="white-logo"
                  alt="logo"
                  style={{ height: "80px" }}
                />
              </a>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav m-auto">
                  <li
                    className="nav-item "
                    onClick={() => {
                      local("#");
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <a
                      href="#"
                      className={path == "#" ? "nav-link active" : ""}
                    >
                      Home
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      local("about-us");
                    }}
                  >
                    <a
                      href="#about-us"
                      className={path == "about-us" ? "nav-link active" : ""}
                    >
                      About Us
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      local("Courses");
                    }}
                  >
                    <a
                      href="#Courses"
                      className={path == "Courses" ? "nav-link active" : ""}
                    >
                      Courses
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      local("JobCourse");
                    }}
                  >
                    <a
                      href="#JobCourse"
                      className={path == "JobCourse" ? "nav-link active" : ""}
                      onClick={() => {
                        show2();
                      }}
                    >
                      Job Courses
                    </a>
                  </li>

                  <li
                    className="nav-item"
                    onClick={() => {
                      local("contact-us");
                    }}
                  >
                    <a
                      href="#contact-us"
                      className={path == "contact-us" ? "nav-link active" : ""}
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className="others-options">
                  <ul className="d-flex justify-content-between align-items-center">
                    <li>
                      <a
                        className="default-btn"
                        onClick={handleShow}
                        type="button"
                      >
                        Get Started
                        <i className="ri-edit-line" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="others-option-for-responsive">
          <div className="container">
            <div className="container">
              <div className="option-inner">
                <div className="others-options justify-content-center d-flex align-items-center">
                  <ul>
                    <li>
                      <a
                        href="our-courses.html"
                        className="default-btn"
                        onClick={handleShow}
                        type="button"
                      >
                        Get Started
                        <i className="ri-edit-line" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} centered>
        <Modal.Header>
          <Modal.Title style={{ color: "#b12b35" }}>
            GET IN TOUCH WITH US!
          </Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            HandleSubmit(e);
          }}
        >
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    Your Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Name"
                    value={form.Name}
                    required
                    placeholder="Enter First Name.."
                    onChange={(e) => {
                      handlechange(e);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    Email address <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="EmailId"
                    value={form.EmailId}
                    placeholder="Enter email.."
                    onChange={(e) => {
                      handlechange(e);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <Form.Group controlId="formFile">
                  <Form.Label>
                    Course Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="CourseName"
                    value={form.CourseName}
                    required
                    placeholder="Enter Course Name.."
                    onChange={(e) => {
                      handlechange(e);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    Phone Number <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="PhoneNumber"
                    value={form.PhoneNumber}
                    required
                    placeholder="Enter Phone Number.."
                    onChange={(e) => {
                      handlechange(e);
                    }}
                  />
                </Form.Group>
              </div>

              <div className="col-md-6 col-sm-12 mb-3">
                <label className="pb-2">Country</label> <span className="text-danger">*</span>
                <select
                  name="Country"
                  onChange={(e) => {
                    handlechange(e);
                  }}
                  controlId="formFile"
                  value={form.Country}
                  required
                  className="form-select"
                  style={{ background: "#f4f4f4" }}
                >
                  <option value="">Select Country</option>
                  <option value="Australia">Australia</option>
                  <option value="Canada">Canada</option>
                  <option value="China">China</option>
                  <option value="Japan"> Japan</option>
                  <option value="India">India</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="Singapore">Singapore</option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Others">Others</option>
                </select>
                {form.Country == "Others" ? (
                  <>
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>
                        Others Country / City <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="City"
                        value={form.City}
                        required
                        placeholder="Enter Country / City.."
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    Comments <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter Comments.."
                    name="Comments"
                    value={form.Comments}
                    onChange={(e) => {
                      handlechange(e);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
        <p className="dot" onClick={handleClose}>
          .
        </p>
      </Modal>
    </>
  );
}

export default Header;
